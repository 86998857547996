const mapLink = "https://goo.gl/maps/gs2Fq6waUn5owkFT8";

export default mapLink;

export const KanhaYoutubeLink = "https://www.youtube.com/embed/jhNPK0r1j3w";

export const nameReg = /^[a-zA-Z ]{2,40}$/;

export const emailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneReg = /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/;

export const forestsReg = /^[+]?(\d[^\d]*){10,12}$/;

export const NOT_APPROVED = "not_approved";

export const FREE_ACCOMMODATION = "Free accommodation";
export const PAID_ACCOMMODATION = "Paid accommodation";
export const OWN_ACCOMMODATION = "Having own accommodation";
export const FREE_ACCOMMODATION_VALUE = "free_accommodation_in_kanha";
export const PAID_ACCOMMODATION_VALUE = "paid_accommodation_in_kanha";
export const OWN_ACCOMMODATION_VALUE = "own_accommodation_arrangements";
